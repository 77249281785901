import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import ContentPoi from "../components/ContentPoi";
import Aside from "../components/Aside";
import ImageModal from "../components/ui/ImageModal";

export const query = graphql`
  query PoiBySlug($slug: String!) {
    sanityPoi(slug: { current: { eq: $slug } }) {
      ...poi
    }
  }
`;

const Poi = ({ data }) => {
  const { slug, seo } = data.sanityPoi;
  // console.log(data.sanityPoi);
  return (
    <div className='poi'>
      {seo && (
        <Seo
          pageTitle={seo.metaTitle}
          pageDescription={seo.metaDescription}
          pageImage={seo.metaImage?.asset.url}
          template={`template-poi poi-${slug.current}`}
          page={true}
          noIndex={false}
        />
      )}

      <Aside autoPop={true}>
        <ContentPoi input={data.sanityPoi} />
      </Aside>
      <ImageModal />
    </div>
  );
};

export default Poi;
