import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { subscribe, unsubscribe } from "pubsub-js";
import SanityImage from "../SanityImage";
import styled from "styled-components";
import Plus from "../../images/plus.inline.svg";

const Container = styled.div`
  height: 100vh;
  overflow-y: auto;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  .gatsby-image-wrapper,
  img {
    width: 100%;
  }
`;

const ImageModal = () => {
  const [expand, setExpand] = useState();
  const [image, setImage] = useState();

  useEffect(() => {
    const token = subscribe("IMAGE_MODAL", (e, data) => {
      console.log(data);
      setImage(data);
      setExpand(true);
    });

    return () => unsubscribe(token);
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", _onKeyDown);
    return () => document.removeEventListener("keydown", _onKeyDown);
  }, [expand]);

  const _onKeyDown = (e) => {
    // console.log(e.key);
    if (e.key === "Escape") {
      setExpand(false);
      setImage(null);
    }
  };

  return (
    <div
      className={clsx(
        "modal fixed  left-0 top-0 w-screen h-screen z-50 bg-black pointer-events-auto cursor-pointer",
        !expand ? "hidden" : ""
      )}
      role='button'
      tabIndex={0}
      onKeyDown={() => setExpand(false)}
      onClick={() => setExpand(false)}>
      <Container>
        <div className='flex-x-y-centered min-h-screen'>
          <div>
            {image && <SanityImage input={image} alt='' />}
            <div
              className='open-wrapper absolute right-0 top-0 z-10 p-sm'
              tabIndex={-1}
              role='button'
              onKeyDown={() => setExpand(false)}
              onClick={() => setExpand(false)}>
              <button className='_open ' aria-label='close image'>
                <span className='icon icon-o close'>
                  <Plus />
                </span>
              </button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ImageModal;
